'use client';

import { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { motion } from 'framer-motion';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Checkbox } from './ui/checkbox';
import { Label } from './ui/label';
import * as React from 'react';

const LoadingBar = ({ isLoading }: { isLoading: boolean }) => (
  <div className="absolute top-0 left-0 h-1 w-full bg-gray-200">
    <motion.div
      className="bg-primary h-full"
      initial={{ width: '0%' }}
      animate={{ width: isLoading ? '100%' : '0%' }}
      transition={{ duration: 1.5, ease: 'easeInOut' }}
    />
  </div>
);

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 2000));

    window.localStorage.setItem('token', JSON.stringify('12345')); // Simulate token'ohifUser');

    window.location.href = '/';
    setIsLoading(false);
  };

  return (
    <div
      className="place-center flex items-center justify-center"
      style={{ background: 'black', height: '100vh', display: 'flex' }}
    >
      <div
        className="relative flex items-center justify-center bg-gray-50"
        style={{ width: '400px' }}
      >
        <LoadingBar isLoading={isLoading} />
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{ padding: '20px', borderRadius: '10px' }}
          className="w-full max-w-md space-y-8 rounded-xl bg-white p-8 shadow-lg"
        >
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-3xl font-extrabold text-gray-900"
            >
              Login
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="mt-2 text-sm text-gray-600"
            >
              Welcome back! Please enter your details.
            </motion.p>
          </div>
          <form
            className="mt-8 space-y-6"
            style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}
            onSubmit={handleSubmit}
          >
            <div className="space-y-4 rounded-md shadow-sm">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
              >
                <Label
                  htmlFor="email-address"
                  className="sr-only"
                >
                  Email address
                </Label>
                <Input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="rounded-md"
                  placeholder="Email address"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
                className="relative"
              >
                <Label
                  htmlFor="password"
                  className="sr-only"
                >
                  Password
                </Label>
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  required
                  className="rounded-md"
                  placeholder="Password"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                  {showPassword ? (
                    <EyeOff
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <Eye
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </motion.div>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="flex items-center"
            >
              <Checkbox id="terms" />
              <Label
                htmlFor="terms"
                className="ml-2 block text-sm text-gray-900"
              >
                I agree to the{' '}
                <a
                  href="#"
                  className="text-primary hover:text-primary-dark"
                >
                  Terms and Conditions
                </a>
              </Label>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              <Button
                type="submit"
                className="w-full"
                disabled={isLoading}
              >
                {isLoading ? 'Signing in...' : 'Sign in'}
              </Button>
            </motion.div>
          </form>
        </motion.div>
      </div>
    </div>
  );
}
